.input-height {
  height: 52px !important;
}

.primary {
  color: #007bff;
}

.info {
  color: #17a2b8;
}

.warning {
  color: #ffc107;
}

.danger {
  color: #dc3545;
}

.success {
  color: #28a745;
}

.object-cover {
  object-fit: cover;
}

.grey-color {
  color: #656973;
}

.link-text {
  text-decoration: none;
  color: $tertiary-color;
}

.link-text:hover {
  color: $secondary-color;
}

.link-text-secondary {
  text-decoration: none;
  color: #04c7c7;
}

.link-text-secondary:hover {
  color: $tertiary-color;
}

.link-text-elite {
  text-decoration: none;
  color: #c70404;
}

.link-text-elite:hover {
  color: #d11a1a;
}

.link-text-accent-elite {
  text-decoration: none;
  color: #04c7c7;
}

.link-text-accent-elite:hover {
  color: #04c7c7;
}

.link-black {
  text-decoration: none;
  color: $primary-color;
}

.link-black:hover {
  color: $primary-color;
  text-decoration: none;
}